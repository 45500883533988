import React, { useState, useEffect } from 'react';
import { request } from 'utils/request';

import DescriptionList from 'components/DescriptionList';
import Dropdown from 'components/Dropdown';
import { StaticCarousel } from './index';

import { displaySizes, frameColors, carouselData as data } from '../museContent';
import { stateObj, museFrameDataType } from '../types';

const museFramesURL = '/physical_objects';
const addToCartURL = '/cart/cart_items';

const Purchase = () => {
  const [museFrameData, setMuseFrameData] = useState([]);
  const [display, setDisplay] = useState(data[0].displaySize);
  const [frameColor, setFrameColor] = useState(data[0].frameColor);
  const [selectedFrame, setSelectedFrame] = useState<stateObj>(data[0]);
  const [selectedImg, setSelectedImg] = useState<string | undefined>(data[0].assets[0]);

  const findCarouselData = (data: stateObj[], selectedSize: string, selectedColor: string) => {
    return data.find(
      (item: stateObj) => item.displaySize === selectedSize && item.frameColor === selectedColor,
    );
  };

  const getMuseFrames = async () => {
    try {
      const res = await request.get(museFramesURL);
      setMuseFrameData(res.data);
    } catch (err) {
      console.log('getMuseFrames error', err);
    }
  };

  const findSelectedMuseFrameEntry = (museFrameData: museFrameDataType[]) => {
    const selectedMuseFrame = museFrameData.find((item: museFrameDataType) => {
      const { displaySize, frameColor } = selectedFrame;
      const { title } = item;
      return title.includes(displaySize.slice(0, -1)) && title.includes(frameColor);
    });
    return selectedMuseFrame;
  };

  const addToCart = async () => {
    const isLoggedIn = window.sedition.currentUser.id !== undefined;
    if (!isLoggedIn) {
      window.location.assign('/login');
      return;
    }

    const found = findSelectedMuseFrameEntry(museFrameData);
    if (found) {
      try {
        const res = await request.post(addToCartURL, {
          purchasables: [
            {
              id: found.id,
              type: 'physical_object',
              quantity: 1,
            },
          ],
        });
        if (res.status === 200) window.location.assign('/cart');
      } catch (err) {
        console.log('Add to Cart error', err);
      }
    }
  };

  const containImg = (selectedImg: string | undefined) => {
    return selectedImg?.includes('.gif') ? '' : 'purchase__product-frame--contain';
  };

  // get muse frames on mount
  useEffect(() => {
    getMuseFrames();
  }, []);

  useEffect(() => {
    const newSelected = findCarouselData(data, display, frameColor);
    if (newSelected) setSelectedFrame(newSelected);
  }, [display, frameColor, selectedFrame, selectedImg]);

  // whenever a new frame is selected, set the first image as the selected image
  useEffect(() => {
    setSelectedImg(selectedFrame.assets[0]);
  }, [selectedFrame]);

  return (
    <section className='purchase' id='purchase'>
      <article className='purchase__top'>
        <StaticCarousel
          selectedFrame={selectedFrame}
          setSelectedImg={setSelectedImg}
          selectedImg={selectedImg}
          className='purchase'
          extraClass={containImg(selectedImg)}
        />
      </article>
      <article className='purchase__bottom'>
        <h2 className='purchase__header'>Get Muse Frame</h2>
        <form className='purchase__form'>
          <div className='purchase__form-container'>
            <label className='purchase__form-label'>Display Size</label>
            <Dropdown setSelected={setDisplay} selected={display} options={displaySizes} />
          </div>
          <div className='purchase__form-container'>
            <label className='purchase__form-label'>Aluminium Frame Colour</label>
            <Dropdown setSelected={setFrameColor} selected={frameColor} options={frameColors} />
          </div>
          <DescriptionList
            items={{
              'Frame Dimensions:': selectedFrame.dimensions.frame,
              'Screen Dimensions:': selectedFrame.dimensions.screen,
            }}
            className='purchase__dimensions'
          />
          <div className='purchase__container'>
            <DescriptionList
              items={{
                'Selected Frame': `${display} / ${frameColor}`,
                'Gift from Sedition': selectedFrame.gift,
                Cost: `$${(selectedFrame.price / 100).toFixed(2)} USD`,
              }}
              className='purchase__summary'
            />
            <button className='btn btn--cta btn__purchase' onClick={addToCart} type='button'>
              Add to Cart
            </button>
          </div>
        </form>
      </article>
    </section>
  );
};

export default Purchase;
