import { CDN_ASSETS_URL } from 'constants/global';

// Image files in s3 bucket
const MUSE_S3_PATH = `${CDN_ASSETS_URL}muse-frame/`;

const HERO_IMAGE_FILE = `${MUSE_S3_PATH}Hero_0001.jpg`;
const MACBOOK_GIF_FILE = `${MUSE_S3_PATH}macbook-device.gif`;
const LEARN_MORE_IMAGE_FILE = `${MUSE_S3_PATH}MuseFrame5_0001-2.jpg`;
const MUSE_INFO_IMAGE_FILES = [
  `${MUSE_S3_PATH}MuseFrame11_0001.jpg`,
  `${MUSE_S3_PATH}MuseFrame2_0010.jpg`,
  `${MUSE_S3_PATH}MuseFrame5_Paris.jpg`,
];

// Image content
export const HERO_IMAGE = {
  src: HERO_IMAGE_FILE,
  alt: "'The Drop' by Ron Arad on a vertical Muse Frame with a black border. It is in a pale blue room with cream and gold furtinure",
  artwork: 'The Drop',
  artist: 'Ron Arad',
};
export const MACBOOK_GIF = {
  src: MACBOOK_GIF_FILE,
  alt: "GIF of managing the reordering of a playlist on Sedition's website, demonstrated on an Apple Macbook",
};
export const LEARN_MORE_IMAGE = {
  src: LEARN_MORE_IMAGE_FILE,
  alt: "'Burning Flower' by Mat Collishaw on a vertical Muse Frame in a brown themed room with a shaft of light from the left. The artwork is a red flower alight with a blue flame on a black background",
  artwork: 'Burning Flower',
  artist: 'Mat Collishaw',
};
export const MUSE_INFO_IMAGES = [
  {
    src: MUSE_INFO_IMAGE_FILES[0],
    artwork: "I Can't Believe How Much I Loved You",
    artist: 'Tracey Emin',
  },
  { src: MUSE_INFO_IMAGE_FILES[1], artwork: 'Portrait E', artist: 'Mike Pelletier' },
  { src: MUSE_INFO_IMAGE_FILES[2], artwork: 'The End of Innocence', artist: 'Mat Collishaw' },
];

// Text content
export const HERO_TEXT = {
  heading: 'The Ultimate Art Display Experience',
  text: 'Display stunning digital artworks directly on a dedicated Muse Frame art screen available in a variety of sizes and colours.',
  buttonText: 'Buy Now',
};
export const CAROUSEL_SECTION_TEXT = {
  smallHeading: 'Access Thousands of Artworks',
  heading: 'Complimentary Art Stream With Your Muse Frame',
  text: 'Art Stream subscription grants access to the entire Sedition collection. Play any available artworks without watermarks and create playlists for any mood or event.',
  text2:
    'Complimentary Art Stream duration is 1 month.',
};
export const LEARN_MORE_TEXT = {
  heading: 'Manage on Sedition, play on Muse Frame',
  text: 'Sedition app on Muse Frame is playback-only. Create your art playlists on Sedition website or mobile apps and use your frame to display and enjoy.',
  buttonText: 'Learn More',
};
export const MUSE_INFO_TEXT = [
  {
    heading: 'Rotatable Viewing Experience',
    text: 'All Muse Frames come with a wall mount that makes it incredibly easy and simple to rotate your frame to your preferred viewing orientation.',
  },
  {
    heading: 'Stereo Sound',
    text: 'All Muse Frames are built with High Definition Speakers and Audio outputs to showcase digital art with all of its intentions.',
  },
  {
    heading: 'Powerful Hardware',
    text: [
      'Advanced Anti-glare Matte Screen',
      'Custom Metal Aluminium Frames',
      'One Thin Power Cord',
      'Powerful CPU',
      '180 Degree Swivel Wall Mount',
      'Thin & Lightweight Design',
      'High Definition Speakers',
      'USB, HDMI, Ethernet and AUX Outlets',
    ],
  },
];
export const SHIPPING_TEXT = {
  heading: 'Shipping and Handling',
  text: "As our exclusive partner, Muse Frame will handle all shipping and handling for your Muse Frame order, ensuring that it arrives at your doorstep in perfect condition. In the unlikely event that you experience any issues with your Muse Frame, Muse Frame's dedicated customer support team is available to assist you with any technical problems you may encounter.",
  text2:
    "Additionally, if you need to return your Muse Frame for any reason, Muse Frame will handle the return process to ensure a smooth and hassle-free experience. With Muse Frame's commitment to customer satisfaction and technical expertise, you can trust that your digital art experience with Sedition is in good hands.",
};

// Note: we decided to go with hard-coded data

export const displaySizes = ['10”', '21.5”', '32”', '55”'];

export const frameColors = ['Black', 'Gold', 'Light Silver'];

export const carouselData = [
  {
    id: 20,
    displaySize: '10”',
    frameColor: 'Black',
    assets: [
      `${MUSE_S3_PATH}previews/10Black_470x650.gif`,
      `${MUSE_S3_PATH}previews/test4WhatsintheBox_10_470x650.jpg`,
      `${MUSE_S3_PATH}previews/10_Back-Black_470x650.jpg`,
      `${MUSE_S3_PATH}previews/10_Front-Black_470x650.jpg`,
    ],
    dimensions: { screen: '13.5cm x 21.5cm x 3.5cm', frame: '17.0cm x 25.0cm x 3.5cm' },
    price: 33300,
    gift: 'Art Stream 1 month',
  },
  {
    id: 21,
    displaySize: '10”',
    frameColor: 'Gold',
    assets: [
      `${MUSE_S3_PATH}previews/10Gold.gif`,
      `${MUSE_S3_PATH}previews/test4WhatsintheBox_10_470x650.jpg`,
      `${MUSE_S3_PATH}previews/10_Back-Gold_470x650.jpg`,
      `${MUSE_S3_PATH}previews/10_Front-Gold_470x650.jpg`,
    ],
    dimensions: { screen: '13.5cm x 21.5cm x 3.5cm', frame: '17.0cm x 25.0cm x 3.5cm' },
    price: 33300,
    gift: 'Art Stream 1 month',
  },
  {
    id: 22,
    displaySize: '10”',
    frameColor: 'Light Silver',
    assets: [
      `${MUSE_S3_PATH}previews/10Silver_470x650.gif`,
      `${MUSE_S3_PATH}previews/test4WhatsintheBox_10_470x650.jpg`,
      `${MUSE_S3_PATH}previews/10_Back-Silver_470x650.jpg`,
      `${MUSE_S3_PATH}previews/10_Front-Silver_470x650.jpg`,
    ],
    dimensions: { screen: '13.5cm x 21.5cm x 3.5cm', frame: '17.0cm x 25.0cm x 3.5cm' },
    price: 33300,
    gift: 'Art Stream 1 month',
  },
  {
    id: 23,
    displaySize: '21.5”',
    frameColor: 'Black',
    assets: [
      `${MUSE_S3_PATH}previews/21Black.gif`,
      `${MUSE_S3_PATH}previews/21_Back-Black_470x650.jpg`,
      `${MUSE_S3_PATH}previews/21_Front-Black_470x650.jpg`,
    ],
    dimensions: { screen: '26.7cm x 47.6cm x 3.5cm', frame: '28.2cm x 49.1cm x 3.5cm' },
    price: 79900,
    gift: 'Art Stream 1 month',
  },
  {
    id: 24,
    displaySize: '21.5”',
    frameColor: 'Gold',
    assets: [
      `${MUSE_S3_PATH}previews/21Gold_470x650.gif`,
      `${MUSE_S3_PATH}previews/21_Back-Gold_470x650.jpg`,
      `${MUSE_S3_PATH}previews/21_Front-Gold_470x650.jpg`,
    ],
    dimensions: { screen: '26.7cm x 47.6cm x 3.5cm', frame: '28.2cm x 49.1cm x 3.5cm' },
    price: 79900,
    gift: 'Art Stream 1 month',
  },
  {
    id: 25,
    displaySize: '21.5”',
    frameColor: 'Light Silver',
    assets: [
      `${MUSE_S3_PATH}previews/21Silver_470x650.gif`,
      `${MUSE_S3_PATH}previews/21_Back-Silver_470x650.jpg`,
      `${MUSE_S3_PATH}previews/21_Front-Silver_470x650.jpg`,
    ],
    dimensions: { screen: '26.7cm x 47.6cm x 3.5cm', frame: '28.2cm x 49.1cm x 3.5cm' },
    price: 79900,
    gift: 'Art Stream 1 month',
  },
  {
    id: 26,
    displaySize: '32”',
    frameColor: 'Black',
    assets: [
      `${MUSE_S3_PATH}previews/32Black_470x650.gif`,
      `${MUSE_S3_PATH}previews/test4WhatsintheBox_470x650.jpg`,
      `${MUSE_S3_PATH}previews/32_Back-Black_470x650.jpg`,
      `${MUSE_S3_PATH}previews/32_Front-Black_470x650.jpg`,
    ],
    dimensions: { screen: '39.3cm x 69.9cm x 3.7cm', frame: '40.8cm x 71.4cm x 3.7cm' },
    price: 99900,
    gift: 'Art Stream 1 month',
  },
  {
    id: 27,
    displaySize: '32”',
    frameColor: 'Gold',
    assets: [
      `${MUSE_S3_PATH}previews/32Gold.gif`,
      `${MUSE_S3_PATH}previews/test4WhatsintheBox_470x650.jpg`,
      `${MUSE_S3_PATH}previews/32_Back-Gold_470x650.jpg`,
      `${MUSE_S3_PATH}previews/32_Front-Gold_470x650.jpg`,
    ],
    dimensions: { screen: '39.3cm x 69.9cm x 3.7cm', frame: '40.8cm x 71.4cm x 3.7cm' },
    price: 99900,
    gift: 'Art Stream 1 month',
  },
  {
    id: 28,
    displaySize: '32”',
    frameColor: 'Light Silver',
    assets: [
      `${MUSE_S3_PATH}previews/32silver_470x650.gif`,
      `${MUSE_S3_PATH}previews/test4WhatsintheBox_470x650.jpg`,
      `${MUSE_S3_PATH}previews/32_Back-Silver_470x650.jpg`,
      `${MUSE_S3_PATH}previews/32_Front-Silver_470x650.jpg`,
    ],
    dimensions: { screen: '39.3cm x 69.9cm x 3.7cm', frame: '40.8cm x 71.4cm x 3.7cm' },
    price: 99900,
    gift: 'Art Stream 1 month',
  },
  // {
  //   id: 29,
  //   displaySize: '43”',
  //   frameColor: 'Black',
  //   assets: [
  //     `${MUSE_S3_PATH}previews/43Black.gif`,
  //     `${MUSE_S3_PATH}previews/test4WhatsintheBox_470x650.jpg`,
  //   ],
  //   dimensions: { screen: '52.9cm x 94.2cm x 4.9cm', frame: '54.4cm x 95.7cm x 4.9cm' },
  //   price: 159900,
  //   gift: 'Art Stream 3 months',
  // },
  // {
  //   id: 30,
  //   displaySize: '43”',
  //   frameColor: 'Gold',
  //   assets: [
  //     `${MUSE_S3_PATH}previews/43Gold.gif`,
  //     `${MUSE_S3_PATH}previews/test4WhatsintheBox_470x650.jpg`,
  //   ],
  //   dimensions: { screen: '52.9cm x 94.2cm x 4.9cm', frame: '54.4cm x 95.7cm x 4.9cm' },
  //   price: 159900,
  //   gift: 'Art Stream 3 months',
  // },
  // {
  //   id: 31,
  //   displaySize: '43”',
  //   frameColor: 'Light Silver',
  //   assets: [
  //     `${MUSE_S3_PATH}previews/43Silver.gif`,
  //     `${MUSE_S3_PATH}previews/test4WhatsintheBox_470x650.jpg`,
  //   ],
  //   dimensions: { screen: '52.9cm x 94.2cm x 4.9cm', frame: '54.4cm x 95.7cm x 4.9cm' },
  //   price: 159900,
  //   gift: 'Art Stream 3 months',
  // },
  {
    id: 32,
    displaySize: '55”',
    frameColor: 'Black',
    assets: [
      `${MUSE_S3_PATH}previews/55Black-min.gif`,
      `${MUSE_S3_PATH}previews/test4WhatsintheBox_470x650.jpg`,
    ],
    dimensions: { screen: '68.0cm x 120.9cm x 4.9cm', frame: '69.5cm x 122.4cm x 4.9cm' },
    price: 234900,
    gift: 'Art Stream 1 month',
  },
  {
    id: 33,
    displaySize: '55”',
    frameColor: 'Gold',
    assets: [
      `${MUSE_S3_PATH}previews/55Gold-min.gif`,
      `${MUSE_S3_PATH}previews/test4WhatsintheBox_470x650.jpg`,
    ],
    dimensions: { screen: '68.0cm x 120.9cm x 4.9cm', frame: '69.5cm x 122.4cm x 4.9cm' },
    price: 234900,
    gift: 'Art Stream 1 month',
  },
  {
    id: 34,
    displaySize: '55”',
    frameColor: 'Light Silver',
    assets: [
      `${MUSE_S3_PATH}previews/55Silver-min.gif`,
      `${MUSE_S3_PATH}previews/test4WhatsintheBox_470x650.jpg`,
    ],
    dimensions: { screen: '68.0cm x 120.9cm x 4.9cm', frame: '69.5cm x 122.4cm x 4.9cm' },
    price: 234900,
    gift: 'Art Stream 1 month',
  },
];
