import React from 'react';

import ArtworkWithText from 'components/ArtworkWithText';
import ImgTextSplit from 'components/ImgTextSplit';
import TextBlock from 'components/TextBlock';

import { MUSE_INFO_IMAGES, MUSE_INFO_TEXT } from '../museContent';

const MuseInfo = () => {
  const MUSE_INFO_CONTENT = MUSE_INFO_IMAGES.map((image, index) => {
    return { ...image, ...MUSE_INFO_TEXT[index] };
  });

  return (
    <div className='muse-info'>
      {MUSE_INFO_CONTENT.map(({ src, artwork, artist, heading, text }, idx) => (
        <ImgTextSplit key={idx} imgSide={idx % 2 === 0 ? 'right' : 'left'} imgWidth='wide'>
          <ArtworkWithText src={src} artwork={artwork} artist={artist} overlaySide='bottom' />
          <TextBlock textColour='white' heading={heading} text={text} />
        </ImgTextSplit>
      ))}
    </div>
  );
};

export default MuseInfo;
