import React, { useEffect, useState } from 'react';

import { request } from 'utils/request';
import { mapLegacyArtworkToArtwork } from 'features/artworks/api';
import * as Types from 'types';

import { HeroBanner, CarouselSection, LearnMore, MuseInfo, Purchase } from './components';
import TextBlock from 'components/TextBlock';
import { SHIPPING_TEXT } from './museContent';

const artworkUrl = '/api/internal/artworks';
const artworkParams = new URLSearchParams([
  ['serializer', 'legacy'],
  ['filter', 'slate_name'],
  ['slate_name', 'carousel-artworks'],
]);

export const MuseFramePage = () => {
  const [artworks, setArtworks] = useState([] as Types.Artwork[]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [isTablet, setIsTablet] = useState(window.innerWidth >= 768 && window.innerWidth <= 1295);

  window.addEventListener('resize', () => {
    setIsMobile(window.innerWidth <= 767);
    setIsTablet(window.innerWidth >= 768 && window.innerWidth <= 1295);
  });

  useEffect(() => {
    request.get(`${artworkUrl}?${artworkParams.toString()}`).then(({ data }) => {
      const artworksArray: Types.Artwork[] = data.map(mapLegacyArtworkToArtwork);
      setArtworks(artworksArray);
    });
  }, []);

  return (
    <main className='muse-frame'>
      <HeroBanner isMobile={isMobile} isTablet={isTablet} />
      <CarouselSection id='carousel-1' initialArtworks={artworks} isMobile={isMobile} />
      <LearnMore />
      <MuseInfo />
      <Purchase />
      <section className='muse-frame__shipping'>
        <div className='muse-frame__content'>
          <TextBlock
            size={isMobile || isTablet ? 'md' : 'sm'}
            textColour='white'
            heading={SHIPPING_TEXT.heading}
            text={SHIPPING_TEXT.text}
            text2={SHIPPING_TEXT.text2}
          />
        </div>
      </section>
    </main>
  );
};
