import React from 'react';

export interface Props {
  src: string;
  alt?: string;
  artwork: string;
  artist: string;
  overlaySide?: 'none' | 'left' | 'right' | 'top' | 'bottom';
  imgOffset?: string;
}

const ArtworkWithText = ({ overlaySide = 'none', imgOffset = '0', ...props }: Props) => {
  return (
    <div className='artwork-with-text'>
      {overlaySide === 'none' ? (
        <img
          src={props.src}
          alt={props.alt}
          className='artwork-with-text__image'
          style={{ objectPosition: `calc(50% + ${imgOffset})` }}
        />
      ) : (
        <div className={`artwork-with-text__overlay artwork-with-text__overlay--${overlaySide}`}>
          <img
            src={props.src}
            alt={props.alt}
            className='artwork-with-text__image'
            style={{ objectPosition: `calc(50% + ${imgOffset})` }}
          />
        </div>
      )}
      <div className='artwork-with-text__content'>
        <p className='artwork-with-text__text artwork-with-text__text--italic'>{props.artwork}</p>
        <p className='artwork-with-text__text'>{props.artist}</p>
      </div>
    </div>
  );
};

export default ArtworkWithText;
