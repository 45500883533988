import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { configureStore } from 'store/configureStore';

import { MuseFramePage } from '../pages/MuseFrame/MuseFramePage';

const museFramePage = document.getElementById('muse-frame-page') as HTMLElement;

const store = configureStore();

if (museFramePage) {
  ReactDOM.render(
    <Provider store={store}>
      <MuseFramePage />
    </Provider>,
    museFramePage,
  );
}
