import React, { useState } from 'react';
import * as Types from 'types';

import TextBlock from 'components/TextBlock';
import { AnimatedCarousel } from 'components/carousel/AnimatedCarousel';
import { CAROUSEL_SECTION_TEXT } from '../museContent';

export interface Props {
  id: string;
  initialArtworks: Types.Artwork[];
  isMobile: boolean;
}

const CarouselSection = (props: Props) => {
  return (
    <>
      <section className='muse-carousel-section'>
        <div className='muse-frame__content'>
          <TextBlock
            className='muse-carousel-section__text text-block--wide'
            alignment={props.isMobile ? 'left' : 'center'}
            heading={CAROUSEL_SECTION_TEXT.heading}
            smallHeading={CAROUSEL_SECTION_TEXT.smallHeading}
            text={CAROUSEL_SECTION_TEXT.text}
            text2={CAROUSEL_SECTION_TEXT.text2}
          />
        </div>
        <AnimatedCarousel id={props.id} initialArtworks={props.initialArtworks} staggered={true} />
      </section>
    </>
  );
};

export default CarouselSection;
