import React from 'react';

type Props = {
  items: { [key: string]: string };
  className: string;
};

const DescriptionList = ({ items, className }: Props) => {
  return (
    <dl className={className}>
      {Object.keys(items).map((key, i) => (
        <div className={`${className}-container`} key={i}>
          <dt className={`${className}-term`}>{key}</dt>
          <dd className={`${className}-details`}>{items[key]}</dd>
        </div>
      ))}
    </dl>
  );
};

export default DescriptionList;
