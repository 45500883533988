import React, { useState, useRef, useEffect } from 'react';

type Props = {
  selected: string;
  setSelected: (param1: string) => void;
  options: string[];
};

const Dropdown = (props: Props) => {
  const { selected, setSelected, options } = props;

  const [open, setOpen] = useState(false);
  const dropRef = useRef<HTMLButtonElement>(null);

  const toggleDropdown = () => {
    setOpen(!open);
  };

  const closeOnOutsideClick = (e: PointerEvent) => {
    if (e.pointerType === 'touch' || e.pointerType === 'mouse' || e.pointerType === 'pen') {
      if (dropRef.current && open && !dropRef.current.contains(e.target as Node)) {
        setOpen(false);
      }
    }
  };

  const onKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter') {
      const option = e.target as HTMLLIElement;
      setSelected(option.innerText);
    }
  };

  useEffect(() => {
    document.addEventListener('pointerdown', closeOnOutsideClick);

    return () => {
      document.removeEventListener('pointerdown', closeOnOutsideClick);
    };
  }, [open]);

  return (
    <button className={`contact-dropdown`} type='button' ref={dropRef} onClick={toggleDropdown}>
      <div className='contact-dropdown__container'>
        <p className='contact-dropdown__label'>{selected}</p>
        <div
          className={`contact-dropdown__img-container icon-arrow-down icon-flip ${
            open ? 'icon-flip--open' : ''
          }`}
        ></div>
      </div>
      <ul className={`contact-dropdown__list ${open ? 'contact-dropdown__list--open' : ''}`}>
        {options.map((option, i) => (
          <li
            className={`contact-dropdown__list-item ${
              selected === option ? 'contact-dropdown__list-item--selected icon-check' : ''
            }`}
            onClick={() => setSelected(option)}
            key={i}
            tabIndex={0}
            onKeyPress={onKeyDown}
          >
            {option}
          </li>
        ))}
      </ul>
    </button>
  );
};

export default Dropdown;
