import React from 'react';

import TextBlock from 'components/TextBlock';
const seditionLogo = require('/app/assets/images/logo.svg') as string;
const museLogo = require('/app/assets/images/muse_logo.svg') as string;

import { HERO_IMAGE, HERO_TEXT } from '../museContent';

export interface Props {
  isMobile: boolean;
  isTablet: boolean;
}

const HeroBanner = (props: Props) => {
  const onClickHandler = () => {
    document.getElementById('purchase')?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <section className='muse-hero'>
      <div
        className='muse-hero__background'
        style={{
          backgroundImage: props.isMobile
            ? `linear-gradient(0deg, #111116 3%, rgba(0, 0, 0, 0) 25%), url(${HERO_IMAGE.src})`
            : props.isTablet
            ? `linear-gradient(90deg, #111116 0%, rgba(0, 0, 0, 0) 50%), url(${HERO_IMAGE.src})`
            : `linear-gradient(90deg, #111116 3%, rgba(0, 0, 0, 0) 40%),
          linear-gradient(270deg, #111116 0%, rgba(0, 0, 0, 0) 10%), url(${HERO_IMAGE.src})`,
        }}
      >
        <p className='muse-frame__content muse-hero__background-text muse-hero__background-text--italic'>
          {HERO_IMAGE.artwork}
        </p>
        <p className='muse-frame__content muse-hero__background-text'>{HERO_IMAGE.artist}</p>
      </div>
      <div className='muse-frame__content'>
        <div className='muse-hero__content'>
          <div className='muse-hero__logos'>
            <img src={seditionLogo} alt='Sedition logo' />
            <i className='icon-default-close'></i>
            <img src={museLogo} alt='Muse logo' />
          </div>
          <TextBlock
            className='muse-carousel-section__text text-block--wide'
            size='lg'
            alignment='left'
            textColour='white'
            heading={HERO_TEXT.heading}
            text={HERO_TEXT.text}
            buttonText={HERO_TEXT.buttonText}
            buttonClass='btn--lg btn--cta'
            onClickHandler={onClickHandler}
          />
        </div>
      </div>
    </section>
  );
};

export default HeroBanner;
