import React, { ReactNode } from 'react';

export interface Props {
  children: ReactNode;
  imgSide: 'left' | 'right';
  imgWidth?: 'wide';
  className?: string;
}

const ImgTextSplit = (props: Props) => {
  return (
    <div
      className={`img-text-split ${
        props.imgSide === 'left' ? 'img-text-split__img--left' : 'img-text-split__img--right'
      } ${props.imgWidth ? `img-text-split--${props.imgWidth}` : null} ${props.className}`}
    >
      {props.children}
    </div>
  );
};

export default ImgTextSplit;
