import React from 'react';

import TextBlock from 'components/TextBlock';
import ArtworkWithText from 'components/ArtworkWithText';

import { MACBOOK_GIF, LEARN_MORE_TEXT, LEARN_MORE_IMAGE } from '../museContent';

const LearnMore = () => {
  return (
    <section className='muse-learn-more'>
      <div className='muse-learn-more__left'>
        <img src={`${MACBOOK_GIF.src}`} alt={MACBOOK_GIF.alt} />
        <TextBlock
          textColour='grey'
          size='sm'
          width='narrow'
          heading={LEARN_MORE_TEXT.heading}
          text={LEARN_MORE_TEXT.text}
          buttonText={LEARN_MORE_TEXT.buttonText}
          buttonHref='https://www.seditionart.com/help-centre/displaying-art#experience-art-on-your-muse-frame-a-quick-guide'
          buttonClass='btn--border-light'
        />
      </div>
      <div className='muse-learn-more__right'>
        <ArtworkWithText
          src={`${LEARN_MORE_IMAGE.src}`}
          alt={LEARN_MORE_IMAGE.alt}
          artwork={LEARN_MORE_IMAGE.artwork}
          artist={LEARN_MORE_IMAGE.artist}
          overlaySide='right'
          imgOffset='-100px'
        />
      </div>
    </section>
  );
};

export default LearnMore;
