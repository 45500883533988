import React from 'react';

import { stateObj } from '../types';

type Props = {
  selectedFrame: stateObj;
  setSelectedImg: (img: string | undefined) => void;
  selectedImg: string | undefined;
  className: string;
  extraClass?: string;
};

const StaticCarousel = ({
  selectedFrame,
  setSelectedImg,
  selectedImg,
  className,
  extraClass,
}: Props) => {
  return (
    <>
      <div
        className={`${className}__product-frame ${extraClass ? extraClass : ''}`}
        style={{ backgroundImage: `url(${selectedImg})` }}
      ></div>
      <div className={`${className}__carousel`}>
        {selectedFrame.assets.map((img, i) => (
          <div
            className={`${className}__carousel-item ${
              img === selectedImg ? `${className}__carousel-item--selected` : ''
            }`}
            style={{ backgroundImage: `url(${img})` }}
            onClick={() => setSelectedImg(img)}
            key={i}
          ></div>
        ))}
      </div>
    </>
  );
};

export default StaticCarousel;
